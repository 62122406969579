<template>
  <div>
    <template v-for="(display,index) in displayData">
      <div
        v-if="displayData"
        :key="index"
        class="row vertical-align import-invoice-data"
      >
        <div
          v-if="display.display"
          class="box box-body box-default"
        >
          <div class="col-xs-half">
            {{ index + 1 }}.
          </div>
          <div class="col-xs-2">
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex === 0"
                :key="`${index}-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-4 current-invoice-data-row"
          >
            <template v-for="(data, dataIndex) in display.display">
              <div
                v-if="dataIndex > 0 && dataIndex < 4"
                :key="`${index}-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-offset-1 col-xs-4 new-invoice-data-row"
          >
            <template v-for="(data, dataIndex) in display.display">
              <div
                v-if="dataIndex > 3"
                :key="`${index}-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <span
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </span>
                </div>
                <span
                  v-if="!Array.isArray(data.value)"
                  class=""
                >{{ data && data.value }}</span>
              </div>
            </template>
            <div class="col-xs-3 invoice-select-align">
              <SelectType
                v-if="!isSummary && !correction"
                v-model="invoiceToOverwrite[index]"
                class=""
                name="invoiceToOverwrite"
                label=""
                :empty-first="true"
                :options="tasksInvoices[display.invoice.number] === undefined ? [] : tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber)"
                :disabled="tasksInvoices[display.invoice.number] === undefined || tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber).length === 0"
              />
              <p v-if="isSummary">
                {{ getActionLabel(display.invoice.action) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SelectType from '../../../share/form/type/SelectType'
import ListMixin from './ListMixin'

export default {
  components: { SelectType },
  mixins: [ListMixin]
}
</script>

<style scoped>
.import-invoice-data strong {
    text-align: end;
}
    .current-invoice-data-row {
        display: flex;
        justify-content: space-evenly;
    }
    .new-invoice-data-row {
        display: flex;
        flex-direction: column;
    }
    .invoice-select-align {
        align-self: flex-end;
    }
</style>
