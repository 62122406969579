<template>
  <div>
    <section class="content">
      <div class="col-sm-12">
        <div class="">
          <div class="row">
            <div>
              <input-type
                v-if="!isSummary"
                v-model="invoiceNumber"
                v-validate="{rules: { required: true }}"
                class="col-xs-2"
                :error-message="vErrors.first('invoiceNumber')"
                label="Numer faktury"
                name="invoiceNumber"
                :required="true"
                data-vv-as="numer faktury"
              />
              <date-picker-type
                v-if="!isSummary"
                v-model="date"
                v-validate="{ rules: { required: true, date_format: 'yyyy-MM-dd' }}"
                class="col-xs-2"
                label="Data"
                name="date"
                :required="true"
                :error-message="vErrors.first('date')"
                data-vv-as="data"
              />
              <div
                class="form-group col-sm-2"
              >
                <br>
                <input
                  v-if="!isSummary"
                  :id="`correction`"
                  v-model="correction"
                  type="checkbox"
                  @click="handleChangeCorrection"
                >
                <label :for="`correction`">Korekta</label>
              </div>
              <input-type
                v-if="!isSummary"
                v-model="originalInvoiceNumber"
                v-validate="{rules: { required: isOriginalNumberRequired }}"
                class="col-xs-2"
                :error-message="vErrors.first('originalInvoiceNumber')"
                :disabled="!correction"
                label="Oryginalny numer faktury"
                name="originalInvoiceNumber"
                :required="isOriginalNumberRequired"
                data-vv-as="Oryginalny numer faktury"
              />
            </div>
            <div
              v-if="!isSummary && !correction"
              class="checkbox-import-overwrite-all col-xs-offset-2 col-xs-2"
            >
              <label for="checkbox">Zaznacz wszystkie możliwe jako nadpisane</label>
              <input
                id="checkbox"
                v-model="checkboxState"
                type="checkbox"
              >
              <label for="checkbox" />
            </div>
          </div>
          <div class="col-xs-12">
            <List
              :invoice-number="invoiceNumber"
              :date="date"
              :data-to-send="dataToSend"
              :is-summary="isSummary"
              :tasks-invoices="tasksInvoices"
              :invoice-to-overwrite="invoiceToOverwrite"
              :display-data="displayData"
              :actions="actions"
              :correction="correction"
            />
          </div>
          <div class="col-xs-12" v-if="service === 'intgen' && ignoredImports.length !== 0">
            <div class="row vertical-align">
              <div class="box box-body box-default">
                <div class="col-xs-12">
                  <strong>Szkody nieodnalezione:</strong>
                  <br/>
                  <p>
                    <template v-for="(ignored, index) in ignoredImports">
                  <span :key="`${ignored.number}index` + index">
                    {{`${ignored.number}${index === ignoredImports.length - 1 ? '.' : ','}`}}</span>
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <blocking-loader :show="loading">
      Przesyłanie danych finansowych
    </blocking-loader>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import DatePickerType from '../../../share/form/type/DatePickerType'
import ErrorNotify from '../../../share/mixins/ErrorNotify'
import InputType from '../../../share/form/type/InputType'
import Loader from '../../../share/Loader'
import WebStorage from '../../../share/WebStorage'
import moment from 'moment'
import List from './ListMain'
import BlockingLoader from '../../../share/BlockingLoader'

export default {
  components: {
    BlockingLoader,
    List,
    DatePickerType,
    InputType
  },
  mixins: [
    ErrorNotify,
    Loader
  ],
  data () {
    return {
      checkboxState: false,
      action: [],
      actions: [
        { label: 'Dodano nowy', value: 1 },
        { label: 'Nadpisano', value: 2 }
      ],
      correction: false,
      loading: false,
      date: '',
      invoiceNumber: '',
      originalInvoiceNumber: '',
      invoiceToOverwrite: [],
      ignoredImports: [],
      tasks: {},
      tasksInvoices: {},
      value: [],
      type: '',
      service: this.$route.meta.acl.service,
      duplicatedIndex: null
    }
  },
  computed: {
    isSummary () {
      return this.$route && this.$route.name.includes('summary')
    },
    isOriginalNumberRequired () {
      return this.correction
    },
    currentAmounts () {
      return this.value.map((element, index) => {
        return this.tasksInvoices.length !== 0 && this.tasksInvoices[element.number] && this.tasksInvoices[element.number]
          .filter(inv => inv.invoiceNumber !== null)
          .map(inv => `${inv.amount} zł`) || ''
      })
    },
    currentDates () {
      let arr = this.value.map((element, index) => {
        return this.tasksInvoices.length !== 0 && this.tasksInvoices[element.number] && this.tasksInvoices[element.number]
          .filter(inv => inv.invoiceNumber !== null && inv.date !== null)
          .map(inv => `${moment(inv.date).format('YYYY-MM-DD')}`) || ''
      })
      return arr
    },
    currentInvoiceNumbers () {
      let arr = this.value.map((element, index) => {
        return this.tasksInvoices.length !== 0 && this.tasksInvoices[element.number] && this.tasksInvoices[element.number]
          .filter(inv => inv.invoiceNumber !== null)
          .map(inv => `${inv.invoiceNumber}`) || ''
      })
      return arr
    },
    invoiceDescription () {
      return this.value.map((element, index) => {
        return this.tasksInvoices.length !== 0 && this.tasksInvoices[element.number] && this.tasksInvoices[element.number]
          .filter(inv => inv.invoiceNumber === null && inv.date === null)
          .map(inv => inv.description).join('; ') || ''
      })
    },
    displayData () {
      return this.dataToSend.map((data, index) => {
        let additionalDisplay = []
        // TODO: avivaClaimNumber make common condition
        if ((this.$route.meta.client === '1' || this.$route.meta.client === 1) && _.has(this.tasks, [data.number, 'avivaClaimNumber'])) {
          additionalDisplay = [{
            label: 'Numer zlecenia: ',
            value: _.has(this.tasks, [data.number, 'avivaClaimNumber']) ? this.tasks[data.number]['avivaClaimNumber'] : ''
          }]
        }
        let display = [
            {
              label: 'Numer zlecenia CRLS: ',
              value: data.number
            },
            {
              label: 'Daty faktur: ',
              value:
                  this.currentDates[index]
            },
            {
              label: 'Numery faktur: ',
              value:
                  this.currentInvoiceNumbers[index]
            },
            {
              label: 'Kwoty za likwidację: ',
              value:
                  this.currentAmounts[index]
            },
            {
              label: 'Nowa data faktury: ',
              value: this.date
            },
            {
              label: 'Nowy numer faktury: ',
              value: this.invoiceNumber
            },
            {
              label: 'Nowa kwota za likwidację: ',
              value:
                data.amount
            },
            {
              label: 'Opis: ',
              value: this.invoiceDescription[index]
            }
          ]
        let el = {
          invoice: data,
          display: [].concat(additionalDisplay, display)
        }
        if (this.service === 'intgen') {
          const additionalElements = [
            {
              label: 'Nr TU: ',
              value: data.number
            },
            {
              label: 'Typ zlecenia rzeczoznawczego: ',
              value: data.orderType
            },
          ]
          additionalElements.forEach(element => {
            el.display.push(element)
          })
          el.display[0].value = data.taskNumber

        }
        return el
      })
    },
    events () {
      return {
        importMain: `${this.service}:finance-import:main`,
        redirect: `dashboard:menu:redirect`
      }
    },
    dataToSend: function () {
      return this.value.map((element, index) => {
        const el = {
          number: element.number,
          currentAmount: element.currentAmount,
          amount: element.amount,
          invoiceNumber: this.invoiceNumber,
          date: this.date || '',
          action: this.invoiceToOverwrite[index] === '' ? this.actions[0].value : this.actions[1].value,
          invoiceToOverwrite: this.invoiceToOverwrite[index] === '' ? null : parseInt(this.invoiceToOverwrite[index])
        }
        if (this.service === 'intgen') {
          el['taskNumber'] = element.taskNumber
          el['orderType'] = element.orderType
        }
        return el
      })
    },
    defaultAction: function () {
      return this.value.map(() => 1)
    },
    defaultInvoiceToOverwrite: function () {
      if (this.isSummary) {
        return this.$route.query.invoiceToOverwrite
      }
      return this.value.map(() => '')
    }
  },
  watch: {
    checkboxState: function (newVal) {
      if (newVal) {
        this.selectAll()
      } else {
        let overwrite = []
        overwrite = this.invoiceToOverwrite.map(el => '')
        this.invoiceToOverwrite = overwrite
      }
    },
    correction: function (newVal) {
      this.checkboxState = false
      let overwrite = []
      overwrite = this.invoiceToOverwrite.map(el => '')
      this.invoiceToOverwrite = overwrite
    }
  },
  mounted () {
    this.$events.on(this.events.importMain, this.sendImportData)
    this.$events.on(this.events.redirect, this.removeImportSpreadsheet)
    this.getImportData()
    this.action = this.defaultAction
    this.invoiceToOverwrite = this.defaultInvoiceToOverwrite
    if (this.isSummary) {
      this.date = this.$route.query.date
      this.invoiceNumber = this.$route.query.invoiceNumber
    }
  },
  beforeDestroy: function () {
  },
  methods: {
    handleChangeCorrection () {
      this.originalInvoiceNumber = ''
    },
    selectAll () {
      let overwrite = []
      this.value.forEach((element, index) => {
        let obj = (Object.entries(this.tasksInvoices)
          .filter(invoice => invoice[0] == element.number)
          .map(el => el[1])[0] || []).filter(el => el.date === null && el.invoiceNumber === null)

        overwrite[index] = obj[0] ? obj[0].value.toString() : ''
      })
      this.invoiceToOverwrite = overwrite
    },
    removeImportSpreadsheet (event) {
      WebStorage.removeImportSpreadsheet()
      this.$router.push({ name: event })
    },
    getActionLabel (value) {
      return this.actions.find(action => action.value === value).label
    },
    getImportData () {
      const data = WebStorage.getImportSpreadsheet()
      this.type = data.type
      this.tasks = data.tasks
      this.value = data.spreadsheetData
      this.tasksInvoices = data.tasksInvoices
      this.ignoredImports = data.ignoredSpreadsheetData || []
    },
    redirectProperly () {
      this.$isWithClients(this.service)
        ? this.$router.push({
          name: `${this.service}_${this.$route.meta.client}_${this.$route.meta.workflowName}_finance_import_invoice_main_summary`,
          query: {
            invoiceToOverwrite: this.invoiceToOverwrite,
            date: this.date,
            invoiceNumber: this.invoiceNumber
          }
        })
        : this.$router.push({
          name: `${this.service}_${this.$route.meta.workflowName}_finance_import_invoice_main_summary`,
          query: {
            invoiceToOverwrite: this.invoiceToOverwrite,
            date: this.date,
            invoiceNumber: this.invoiceNumber
          }
        })
    },
    getRequestMethod () {
      let data = {}
      data.items = this.dataToSend.map(el => {
        if (this.service === 'intgen') {
          el.number = el.taskNumber
        }
        return el
      })
      data.correction = this.correction
      data.originalInvoiceNumber = this.originalInvoiceNumber
      return this.$isWithClients(this.$route.meta.acl.service)
        ? api.request(this.$route.meta.acl.service, 'put', `invoices`, data)
        : api.request(this.$route.meta.acl.service, 'put', `/tasks/invoices`, data)
    },
    sendImportData () {
      if (this.dataToSend.length === 0) {
        this.$notify({
          type: 'warn',
          text: 'Brak danych do importu'
        })
        return
      }

      // check for duplicates in invoiceToOverwrite
      const invoiceToOverwriteDuplicates = this.invoiceToOverwrite.filter((invoice, index, arr) => invoice !== '' && arr.indexOf(invoice) !== index)
      this.duplicatedIndex = this.invoiceToOverwrite.map((invoice, index, arr) => invoice !== '' && arr.indexOf(invoice) !== index ? this.displayData[index].invoice.number : false).filter(element => element)

      if (invoiceToOverwriteDuplicates.length > 0) {
        this.$notify({
          type: 'warn',
          text: `W ramach zlecenia, nadpisanie jednej kwoty wieloma wartościami nie jest dozwolone. ${this.duplicatedIndex.length !== 1 ? ' Dotyczy zleceń ' + this.duplicatedIndex.join(',') : ' Dotyczy zlecenia ' + this.duplicatedIndex[0]}`
        })
        return
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.toggleLoading()
          this.loading = true
          this.getRequestMethod()
            .then((response) => {
              this.$notify({
                type: 'success',
                text: 'Zaimportowano faktury'
              })
              this.loading = false
              this.redirectProperly()
            })
            .catch((error) => {
              this.loading = false
              this.errorNotify(error)
            })
        }
      })
    }
  }
}
</script>
<style>
    .checkbox-import-overwrite-all {
        display: flex;
    }
    .checkbox-import-overwrite-all label {
        cursor: pointer;
    }
</style>
